const contacts = [
    {
        "name": "Github",
        "icon": "assets/github.svg",
        "link": "https://github.com/ilyes-i-ben"
    },
    {
        "name": "Instagram",
        "icon": "assets/instagram.svg",
        "link": "https://www.instagram.com/flick.json/"
    },
    {
        "name": "Discord",
        "icon": "assets/discord.svg",
        "link": "https://discord.com/users/577182678818881556"
    },
    {
        "name": "Facebook",
        "icon": "assets/facebook.svg",
        "link": "https://www.facebook.com/benameur.ilyesismail/"
    },
    {
        "name": "LinkedIn",
        "icon": "assets/linkedin.svg",
        "link": "https://www.linkedin.com/in/ilyes-ismail-benameur-715b9b197/"
    },

]

const headerElements = [
    { "id": 1, "title": "About", "svg-name": "about", "href": "#about" },
    { "id": 2, "title": "Biography", "svg-name": "biography", "href": "#biography" },
    { "id": 3, "title": "Career", "svg-name": "career", "href": "#career" },
    { "id": 4, "title": "Skills", "svg-name": "skills", "href": "#skillset" },
    { "id": 5, "title": "Projects", "svg-name": "projects", "href": "#projects" },
    { "id": 6, "title": "Contact", "svg-name": "contact", "href": "#contact" }
]

/* const skillset =
{
    "row-1": [
        {
            "id": 1,
            "name": "Angular",
            "icon": "assets/skillset/angular.svg"
        },
        {
            "id": 2,
            "name": "React",
            "icon": "assets/skillset/react.svg"
        },
        {
            "id": 3,
            "name": "Vue",
            "icon": "assets/skillset/vuejs.svg"
        },
        {
            "id": 4,
            "name": "Next Js",
            "icon": "assets/skillset/nextjs.svg"
        },
        {
            "id": 5,
            "name": "Tailwind CSS",
            "icon": "assets/skillset/tailwindcss.svg"
        },
        {
            "id": 6,
            "name": "Bootstrap CSS",
            "icon": "assets/skillset/bootstrapcss.svg"
        },
        {
            "id": 7,
            "name": "Redux",
            "icon": "assets/skillset/redux.svg"
        },
        {
            "id": 8,
            "name": "Gatsby",
            "icon": "assets/skillset/gatsby.svg"
        }
    ],
    "row-2": [
        {
            "id": 1,
            "name": "Laravel",
            "icon": "assets/skillset/laravel.svg"
        },
        {
            "id": 2,
            "name": "Flask",
            "icon": "assets/skillset/flask.svg"
        },
        {
            "id": 3,
            "name": "Django",
            "icon": "assets/skillset/django.svg"
        },
        {
            "id": 4,
            "name": "Spring",
            "icon": "assets/skillset/spring.svg"
        },
        {
            "id": 5,
            "name": "Node Js",
            "icon": "assets/skillset/nodejs.svg"
        },
        {
            "id": 6,
            "name": "Express Js",
            "icon": "assets/skillset/expressjs.svg"
        }
    ],
    "row-3": [
        {
            "id": 1,
            "name": "MySql",
            "icon": "assets/skillset/mysql.svg"
        },
        {
            "id": 2,
            "name": "Oracle",
            "icon": "assets/skillset/oracle.svg"
        },
        {
            "id": 3,
            "name": "MongoDB",
            "icon": "assets/skillset/mongodb.svg"
        },
        {
            "id": 4,
            "name": "PostgreSQL",
            "icon": "assets/skillset/postgresql.svg"
        },
        {
            "id": 5,
            "name": "Firebase",
            "icon": "assets/skillset/firebase.svg"
        },
        {
            "id": 6,
            "name": "GraphQL",
            "icon": "assets/skillset/graphql.svg"
        }
    ],
    "row-4": [
        {
            "id": 1,
            "name": "Linux",
            "icon": "assets/skillset/linux.svg"
        },
        {
            "id": 2,
            "name": "Postman",
            "icon": "assets/skillset/postman.svg"
        },
        {
            "id": 3,
            "name": "Electron Js",
            "icon": "assets/skillset/electronjs.svg"
        },
        {
            "id": 4,
            "name": "Git",
            "icon": "assets/skillset/git.svg"
        },
        {
            "id": 5,
            "name": "Scrum",
            "icon": "assets/skillset/scrum.svg"
        }
    ],
    "row-5": [
        {
            "id": 1,
            "name": "Wordpress",
            "icon": "assets/skillset/wordpress.svg"
        },
        {
            "id": 2,
            "name": "Adobe Photoshop",
            "icon": "assets/skillset/photoshop.svg"
        },
        {
            "id": 3,
            "name": "Adobe Illustrator",
            "icon": "assets/skillset/illustrator.svg"
        },
        {
            "id": 4,
            "name": "Figma",
            "icon": "assets/skillset/figma.svg"
        }
    ]

} */

const skillset = [
    /* {
        "id": 1,
        "name": "Angular",
        "icon": "assets/skillset/angular.svg"
    }, */
    {
        "id": 2,
        "name": "React",
        "icon": "assets/skillset/react.svg"
    },
    /* {
        "id": 3,
        "name": "Vue",
        "icon": "assets/skillset/vuejs.svg"
    }, */
    {
        "id": 4,
        "name": "Next Js",
        "icon": "assets/skillset/nextjs.svg"
    },
    {
        "id": 5,
        "name": "Tailwind CSS",
        "icon": "assets/skillset/tailwindcss.svg"
    },
    {
        "id": 6,
        "name": "Bootstrap CSS",
        "icon": "assets/skillset/bootstrapcss.svg"
    },
    /* {
        "id": 7,
        "name": "Redux",
        "icon": "assets/skillset/redux.svg"
    }, */
    {
        "id": 8,
        "name": "Gatsby",
        "icon": "assets/skillset/gatsby.svg"
    },
    {
        "id": 9,
        "name": "Laravel",
        "icon": "assets/skillset/laravel.svg"
    },
    {
        "id": 10,
        "name": "Symfony",
        "icon": "assets/skillset/symfony.svg"
    },
    {
        "id": 11,
        "name": "Docker",
        "icon": "assets/skillset/docker.svg"
    },
    {
        "id": 12,
        "name": "JWT",
        "icon": "assets/skillset/jwt.svg"
    },
    {
        "id": 13,
        "name": "Node Js",
        "icon": "assets/skillset/nodejs.svg"
    },
    {
        "id": 14,
        "name": "Express Js",
        "icon": "assets/skillset/expressjs.svg"
    },
    {
        "id": 15,
        "name": "MySql",
        "icon": "assets/skillset/mysql.svg"
    },
    {
        "id": 16,
        "name": "Oracle",
        "icon": "assets/skillset/oracle.svg"
    },
    {
        "id": 17,
        "name": "MongoDB",
        "icon": "assets/skillset/mongodb.svg"
    },
    /* {
        "id": 18,
        "name": "PostgreSQL",
        "icon": "assets/skillset/postgresql.svg"
    }, */
    // {
    //     "id": 19,
    //     "name": "Firebase",
    //     "icon": "assets/skillset/firebase.svg"
    // },
    {
        "id": 20,
        "name": "GraphQL",
        "icon": "assets/skillset/graphql.svg"
    },
    {
        "id": 21,
        "name": "Linux",
        "icon": "assets/skillset/linux.svg"
    },
    {
        "id": 22,
        "name": "Postman",
        "icon": "assets/skillset/postman.svg"
    },
    // {
    //     "id": 23,
    //     "name": "Electron Js",
    //     "icon": "assets/skillset/electronjs.svg"
    // },
    {
        "id": 24,
        "name": "Git",
        "icon": "assets/skillset/git.svg"
    },
    {
        "id": 25,
        "name": "Scrum",
        "icon": "assets/skillset/scrum.svg"
    },
    // {
    //     "id": 26,
    //     "name": "Wordpress",
    //     "icon": "assets/skillset/wordpress.svg"
    // },
    {
        "id": 27,
        "name": "Adobe Photoshop",
        "icon": "assets/skillset/photoshop.svg"
    },
    {
        "id": 28,
        "name": "Adobe Illustrator",
        "icon": "assets/skillset/illustrator.svg"
    },
    {
        "id": 29,
        "name": "Figma",
        "icon": "assets/skillset/figma.svg"
    }
];



const openLink = (link) => {
    window.open(link, '_blank');
}



const projectAlternatives = [
    "Showcase Project",
    "Highlighted Project",
    "Prominent Project",
    "Spotlight Project",
    "Prime Project",
    "Notable Project"
];

const getRandomProject = () => {
    const randomIndex = Math.floor(Math.random() * projectAlternatives.length);
    return projectAlternatives[randomIndex];
};


const pdfUrl = 'assets/CV_ALT_FR.pdf';
const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'CV_ALT_EN.pdf';
    link.click();
};

const pdfUrlEN = 'assets/CV_ALT_EN.pdf';
const downloadPdfEn = () => {
    const link = document.createElement('a');
    link.href = pdfUrlEN;
    link.download = 'CV_ALT_EN.pdf';
    link.click();
};

const pointerTailwindScript = "before:pointer-events-none before:fixed before:inset-0 before:z-0 before:bg-[radial-gradient(600px_at_var(--x)_var(--y),_rgba(179,98,219,0.25),_transparent_80%);]";




export { contacts, openLink, headerElements, skillset, getRandomProject, pointerTailwindScript, downloadPdf, downloadPdfEn };