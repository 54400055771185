import React from 'react'
import { getRandomProject, openLink } from './shared/materials'


const Project = ({ projectName, projectLink, projectDesc, projectTechStack, projectScreenshot }) => {
    return (
        <div className='flex flex-col  items-center justify-center w-[full]'>

            <div className='flex flex-col gap-8 pl-8'>
                {/* <div className='flex flex-col'>
                    
                </div> */}

                <div className='flex project-link sm:text-[2.5rem] md:text-[2rem] underline flex items-center cursor-pointer' onClick={() => openLink(projectLink)}> <img src="assets/github.svg" alt="project-link-icon" className=' h-[1rem] sm:h-[1.5rem] pl-2 mr-2' /> {projectName} <img src="assets/project-link.svg" alt="project-link-icon" className=' h-[1rem] sm:h-[1.5rem] pl-2' /></div>
                {/* <div className='rounded-xl  h-[60%] sm:h-[80%]  md:h-[full] w-[40rem] py-2 career-bio p-5'>
                    
                </div> */}

                <p className="biography-text-decoration">
                    {projectDesc}
                </p>

                <img src={`assets/${projectScreenshot}.png`} alt={`${projectName}-screenshot`} />




            </div>
            <div className='basis-1/4 m-16 p-8 flex-start md:w-[40rem] md:h-[20rem]'>
                <div className="flex flex-wrap   gap-6 justify-center">
                    {
                        projectTechStack.map((tech) => {
                            return (
                                <img title={tech} src={`assets/skillset/${tech}.svg`} alt={tech} className='md:w-[3.5rem] md:h-[3.5rem] w-[2.5rem] h-[2.5rem] object-contain' />
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default Project