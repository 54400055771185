import React, { useEffect, useRef } from 'react'
import { register } from "swiper/element/bundle";
import Project from './Project';
import projects from './shared/projects';
import useWindowSize from '../utils/useWindowSize';
register();


const ProjectSwiper = ({ style }) => {


    const swiperRef = useRef(null);

    const { width } = useWindowSize();

    useEffect(() => {
        const swiperContainer = swiperRef.current;
        const params = {


            // navigation: false,
            freeMode: true,
            loop: true,
            grabCursor: true,
            pagination: {
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 1,
            },
            autoplay: {
                delay: 2400
            },
            injectStyles: [
                ` 
                .swiper-button-next, .swiper-button-prev
                {
                    height: 7rem;
                    width: 7rem;
                    background-color: black;
                    border-radius: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    border: 4px solid #B362DB;
                    color:transparent;
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 150ms;
                    align-self: center;
                    background-size: 4rem 4rem;
                    display: none;
                }

                .swiper-button-next:hover, .swiper-button-prev:hover{
                    background-color: #3B3B3B;
                }

                .swiper-button-next{
                    background-image: url(assets/right-arrow.svg);
                }

                .swiper-button-prev{
                background-image: url(assets/left-arrow.svg);
                }

                .swiper-pagination-bullet{
                    width: 10px;
                    height: 10px;
                    background-color: #b362db;
                }

              `,
            ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    }, []);


    return (
        <swiper-container ref={swiperRef} init="false" style={style}>

            {
                projects.map((project, index) => {
                    return <swiper-slide key={index}>
                        <Project
                            key={index}
                            projectName={project.name}
                            projectLink={project.link}
                            projectDesc={project.desc}
                            projectTechStack={project.techStack}
                            projectScreenshot={project.screenshot}
                        />
                    </swiper-slide>
                })
            }

        </swiper-container>
    )
}

export default ProjectSwiper