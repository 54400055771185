const projects = [
    {
        "id": 1,
        "name": "Tali3",
        "link": "https://github.com/Team-G4-15/Tali3",
        "desc": "Group of 6 🎓Students applied SCRUM methodology 🔄, utilized ReactJS and Laravel 💻 to develop a Software library management system 📚. It enables librarians to effortlessly track books, manage loans, returns, and due dates 📆.",
        "techStack": ["react", "laravel", "bootstrapcss", "mysql", "php", "composer", "javascript", "scrum", "axios", "git"],
        "screenshot": "tali3"
    },
    {
        "id": 2,
        "name": "Skill&Tell - Student Club Website",
        "link": "https://github.com/RaidOuahioune/SkillnTellServer",
        "desc": "Group of 3 🎓Students applied MVC Design Pattern 🔄, utilized ReactJS and Laravel 💻 to create a cutting-edge platform. It's powered by a chat app 📱 and a meticulously designed database. Find the database schema in the GitHub repo 📊.",
        "techStack": ["react", "laravel", "bootstrapcss", "mysql", "php", "composer", "javascript", "scrum", "pusher", "axios", "git"],
        "screenshot": "skillntell"
    },
    {
        "id": 3,
        "name": "My Web Developer Portfolio",
        "link": "https://ilyes-b.vercel.app/",
        "desc": "My portfolio, built with React.js and Tailwind CSS, features Next UI, SwiperJS, and Framer Motion 🚀. I also crafted a custom React hook (useWindowSize) 🖼️. Prior to coding, I designed the UI in Figma for seamless frontend development. Although it's a single-page layout, it's dynamic and scalable, especially the projects section, ready to handle more data 💻.",
        "techStack": ["react", "swiper", "tailwindcss", "nextjs", "javascript", "figma", "illustrator", "git"],
        "screenshot": "portfolio"
    },
    /* {
        "id": 4,
        "name": "Whatever - (Coming Soon)",
        "link": "https://www.linkedin.com/in/ilyes-ismail-benameur-715b9b197/",
        "desc": "My portfolio, built with React.js and Tailwind CSS, features Next UI, SwiperJS, and Framer Motion 🚀. I also crafted a custom React hook (useWindowSize) 🖼️. Prior to coding, I designed the UI in Figma for seamless frontend development. Although it's a single-page layout, it's dynamic and scalable, especially the projects section, ready to handle more data 💻.",
        "techStack": ["react", "swiper", "tailwindcss", "nextjs", "javascript", "figma", "illustrator", "git"],
        "screenshot": "portfolio"
    }, */
]

export default projects;