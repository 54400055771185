import { useInView } from 'framer-motion';
import React, { useRef } from 'react'
import { openLink } from './shared/materials';
import { Card, CardBody, CardFooter, CardHeader, Divider, Image, Link } from '@nextui-org/react';

const Career = () => {
    const ensiaRef = useRef(null);
    const etcRef = useRef(null);
    const gdscRef = useRef(null);
    const unicaenRef = useRef(null);
    const esgiRef = useRef(null);
    const codsoftRef = useRef(null);

    // const isEnsiaInView = useInView(ensiaRef, { once: true });
    // const isEtcInView = useInView(etcRef, { once: true });
    // const isGdscInView = useInView(gdscRef, { once: true });
    // const isUnicaenInView = useInView(unicaenRef, { once: true });


    return (
        <div id='career' className="flex  justify-center content-center  container max-w-screen-xl mx-auto">

            <div className="flex flex-col gap-y-7 w-[90%] max-w-screen-xl mx-auto sm:w-[64%]">


                {/* Here goes the title of the section Career */}

                <div className='flex flex-col'>
                    <span className='title-style'>Career</span>
                    {/* <img src="assets/career-underline.svg" alt="underline" className='w-[13.625rem]' /> */}
                </div>

                <div
                    className={`biography-text-decoration`}
                >
                    Embark on a journey through my career milestones! 🚀✨
                </div>
                {/* <div
                    className={`biography-text-decoration`}
                >
                    Along my learning journey, I've truly understood the value of hands-on experience – the best way to learn is by doing.✨<br /><br />
                    My ultimate goal is to work on real projects in a corporate setting, enhancing my skills while continuing my studies.✅<br /><br />
                    Eagerly awaiting the chance to join a tech community, I'm excited to embark on a web developement apprenticeship! 🙌
                </div> */}

                {/* Ensia Here */}








                <Card
                    ref={esgiRef}
                    className={`h-full cards-background font-[600] sm:font-[700]  text-justify text-[1rem] sm:text-[1.25rem] lg:text-[1.5rem] show`}
                >
                    <CardHeader className='flex gap-3' >
                        <Image src="assets/esgi.webp" alt="esgi-logo" className='w-[80px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>Ecole Supérieure de Génie Informatique</p>
                            <p className='text-medium font-[700]'>Paris - France | (September 2024 - Future Student)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>Admitted to the 3rd year apprenticeship program in Web Engineering, pursuing a Bachelor's degree, starting in September 2024. </p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://www.esgi.fr/"
                        >
                            Visit The Official ESGI School Website
                        </Link>
                    </CardFooter>
                </Card>





                <Card
                    ref={codsoftRef}
                    className={`h-full cards-background font-[600] sm:font-[700]  text-justify text-[1rem] sm:text-[1.25rem] lg:text-[1.5rem] show`}
                >
                    <CardHeader className='flex gap-3' >
                        <Image src="assets/codsoft-logo.png" alt="codsoft-logo" className='w-[80px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>Web Developement Intern at CodSoft</p>
                            <p className='text-medium font-[700]'>Remote | (March 2024 - Current)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>I have been selected as an intern in CodSoft, where we develop, learn and practice a multitude of web technologies like ReactJS, NextJS, NodeJS, ExpressJS, MongoDB & more.</p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://www.codsoft.in/"
                        >
                            Visit The Official CodSoft Website
                        </Link>
                    </CardFooter>
                </Card>








                <Card
                    ref={unicaenRef}
                    className={`h-full cards-background font-[600] sm:font-[700]  text-justify text-[1rem] sm:text-[1.25rem] lg:text-[1.5rem] show`}
                >
                    <CardHeader className='flex gap-3' >
                        <Image src="assets/unicaen-logo.svg" alt="unicaen-logo" className='w-[80px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>University Of Caen Normandy</p>
                            <p className='text-medium font-[700]'>Caen - France | (September 2023 - Current)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>I am a student in the university of Caen Normandy, diligently pursuing my Licence's degree, currently a second year student in this training, already gathering an interesting skillset !</p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://www.unicaen.fr/"
                        >
                            Visit The University Website
                        </Link>
                    </CardFooter>
                </Card>



                <Card
                    ref={etcRef}
                    className={`cards-background h-full show`}
                >

                    <CardHeader className='flex gap-3' >
                        <Image src="assets/etcLogo.svg" alt="etc-logo" className='w-[80px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>ENSIA Tech Community</p>
                            <p className='text-medium font-[700]'>Algiers - Algeria | (March 2022 - July 2023)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>I held the esteemed position of Co-Founder, I was a co-manager of communication department, organizer and participant in a multitude of events, an experience that definitely worth sharing !</p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://etc-club.vercel.app"
                        >
                            Visit The Official Website Of ETC Club
                        </Link>
                    </CardFooter>
                </Card>
                <Card
                    ref={gdscRef}
                    className={`cards-background h-full  show`}
                >

                    <CardHeader className='flex gap-3' >
                        <Image src="assets/gdsc.svg" alt="gdsc-logo" className='w-[80px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>Google Developer Student Club</p>
                            <p className='text-medium font-[700]'>Algiers - Algeria | (October 2022 - July 2023)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>I held a position as an IT member, and a mobile development team member, Participated as an organizer in their biggest event (hackathon) in 2023, AiFest'23 !</p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://gdsc.community.dev/ecole-nationale-superieure-de-lintelligence-artificielle-algiers/"
                        >
                            Visit The Official Website Of GDSC ENSIA
                        </Link>
                    </CardFooter>
                </Card>



                <Card
                    ref={ensiaRef}
                    className={`cards-background h-full show`}
                >

                    <CardHeader className='flex gap-3' >
                        <Image src="assets/ensia-logo.png" alt="ensia-logo" className='p-2 w-[100px]' />
                        <div className='flex flex-col'>
                            <p className='text-[1] sm:text[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-[#11071F] font-[700]'>National Higher School Of Artificial Intelligence</p>
                            <p className='text-medium font-[700]'>  Algiers - Algeria | (September 2021 - July 2023)</p>
                        </div>
                    </CardHeader>
                    <Divider />
                    <CardBody className='flex justify-center'>
                        <p className='career-bio-text'>During my two-year preparatory cycle at this school, I had an incredible journey where I acquired a wide range of skills, including stress management, teamwork, and communication</p>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <Link
                            isExternal
                            showAnchorIcon
                            className='career-link-text'
                            href="https://www.ensia.edu.dz/"
                        >
                            Visit The Official ENSIA School Website
                        </Link>
                    </CardFooter>
                </Card>



                <div
                    className='animate-bounce w-[2rem] sm:w-[3rem] justify-self-end self-center py-6 cursor-pointer'
                >
                    <a href="#skillset">
                        <img src="assets/twoarrows.svg" alt="arrows" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Career