import Header from './components/Header';
import Hero from './components/Hero';
import Biography from './components/Biography';
import Career from './components/Career';
import { useEffect, useRef, useState } from 'react';
import Skillset from './components/Skillset';
import Contact from './components/Contact';
import Projects from './components/Projects';
import { NextUIProvider } from '@nextui-org/react';
import useWindowSize from './utils/useWindowSize';
import { pointerTailwindScript } from './components/shared/materials';

function App() {
  const targetRef = useRef(null);
  const { width } = useWindowSize();
  const [pointer, setPointer] = useState(null);


  useEffect(() => {
    // Update pointer based on width
    setPointer(width > 640 ? pointerTailwindScript : null);
  }, [width]);

  useEffect(() => {
    const updateMousePosition = (ev) => {
      if (!targetRef.current) return;
      const { clientX, clientY } = ev;
      targetRef.current.style.setProperty("--x", `${clientX}px`);
      targetRef.current.style.setProperty("--y", `${clientY}px`);
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);




  return (
    <NextUIProvider>
      <div className={pointer} ref={targetRef}>
        <Header />
        <Hero />
        <Biography />
        <Career />
        <Skillset />
        <Projects />
        <Contact />
      </div>
    </NextUIProvider>
  );
}

export default App;
