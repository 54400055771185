import React from 'react'
import ProjectSwiper from './ProjectSwiper'



// container div className = 'flex flex-col justify-center content-center items-center space-x-3.5 h-[100vh]'




const Projects = () => {
    return (
        <div id={'projects'} className='flex  justify-center content-center container max-w-screen-xl mx-auto '>
            <div className="flex flex-col gap-8 w-[90%] sm:w-[64%]">
                <div className='flex flex-col self-start'>
                    <span className='title-style text-left'>Projects</span>
                </div>


                <div className="flex items-center content-center ">
                    <ProjectSwiper style={{ minWidth: 0 }} />
                </div>

                <div className='animate-bounce w-[2rem] sm:w-[3rem] self-center py-6 cursor-pointer'>
                    <a href="#contact">
                        <img src="assets/twoarrows.svg" alt="arrows" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Projects