import React, { useEffect, useRef } from 'react'
import { motion, useInView } from 'framer-motion';
import { Button } from '@nextui-org/react';
import { downloadPdf } from './shared/materials';


const Biography = () => {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div id={"biography"} className="flex justify-center container content-center max-w-screen-xl mx-auto h-[100vh] ">


            <div className="flex flex-col justify-center gap-y-16 w-[90%] sm:w-[64%]">
                <div className='text-left'>
                    <span className='title-style'>Biography</span>
                </div>

                <div
                    ref={ref}
                    className={` biography-text-decoration  custom-hidden ${isInView && 'show'}`}
                >
                    I'm a 19 years old full-stack developer & a software engineer hooked on mastering the latest in web tech and currently interested in Mobile Development and DevOps. 💡☁️
                    <br /><br />
                    Kickstarted coding at a tender age of 16 with C++, now I craft cutting-edge web projects, using the most trending web technologies.🚀
                    <br /><br />
                    I have a strong dose of curiosity and an insatiable appetite for learning. I strive to expand my skills in every field I encounter.🌟
                    <br /><br />
                    📄Feel free to grab a pdf version of my CV for more ! (Contact Section) 👇
                </div>

                {/* HERE DOWNLOAD BUTTON */}

                <div
                    className='animate-bounce  w-[2rem] sm:w-[3rem]  justify-self-end self-center py-6 cursor-pointer'
                >
                    <a href="#career">
                        <img
                            src="assets/twoarrows.svg"
                            alt="arrows"
                        />
                    </a>
                </div>


            </div>
        </div >


    )
}

export default Biography