import { useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import useWindowSize from '../utils/useWindowSize';
const Hero = () => {




    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });


    const { width } = useWindowSize();

    // const { biographyRef } = useContext(refContext);



    const greeting = <div
        className=" text-left font-[700] md:font-[900] text-[2.5rem] sm:text-[3rem] lg:text-[5rem]   pl-2 sm:pl-0  mt-10 sm:mt-0 flex flex-col sm:flex-row w-full"
    >

        <div className={`title custom-hidden ${isInView && "show"}`}>
            Hello, I'm&nbsp;
        </div>
        <div className={`title custom-hidden ${isInView && "show-after"}`}>
            <span className=' text-ilyes-purple'>ILYES BENAMEUR</span> !
        </div>
    </div>

    const avatarPc = <div className={` h-[25rem] sm:h-[40rem] w-[25rem] sm:w-[40rem] self-center shrink-0 mr-0`}>
        <img src="assets/me-pointer-gradiented.png" alt="Me" className=" w-full h-full " />
    </div>

    const avatarMobile = <div className={` h-[25rem] sm:h-[40rem] w-[25rem] sm:w-[40rem] self-center shrink-0 mr-0`}>
        <img src="assets/me-pointed-gradiented-mobile.png" alt="Me" className=" w-full h-full " />
    </div>


    const avatar = (width > 640 ? avatarPc : avatarMobile)

    return (
        <div id="about" ref={ref} className='flex flex-col items-center justify-center max-w-screen-xl mx-auto content-center sm:mt-0 container h-[100vh]'>

            <div className='flex flex-col  sm:flex-row  content-center'>
                {width <= 640 && greeting}

                {/* {avatar} */}



                <div id='hero-text' className='flex flex-col'>
                    {width > 640 && greeting}
                    <div className={`flex flex-col gap-6  hero-desc font-[700] my-24 custom-hidden-2 ${isInView && "show-hero-desc"}`}>
                        <span>
                            A Developer who
                        </span>
                        <div className=' text-[1.5rem] sm:text-[3rem]'>
                            Bridges the gap between <br />
                            code & <span className='text-ilyes-purple'>creativity</span> ...
                        </div>
                        <span>
                            <span className="text-ilyes-purple">Aestethics</span> matter! doesn't it ?
                        </span>
                    </div>
                    <div className={` font-[900]  text-left text-[1.25rem] sm:text-[2rem]   mb-5 custom-hidden ${isInView && "show-hero-animation"}`}>

                        <span className="">I'm a </span>
                        <TypeAnimation
                            sequence={[
                                1000,
                                "Software Engineer.",
                                1000,
                                "Full Stack Developer.",
                                1000,
                                "UI / UX Designer.",
                                1000,
                                "Graphic Designer.",
                                1000
                            ]}
                            repeat={Infinity}
                            className='font-[900] text-ilyes-purple'
                        />
                    </div>

                </div>
            </div>
            <div
                className='mt-10 animate-bounce w-[2rem] sm:w-[3rem] m- justify-self-end self-center py-6 cursor-pointer'
            >
                <a href="#biography">
                    <img src="assets/twoarrows.svg" alt="arrows" />
                </a>
            </div>
        </div>

    );
};

export default Hero;
