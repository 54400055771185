import React, { Children, useRef } from 'react'
import { skillset } from './shared/materials'
import { useInView } from 'framer-motion';
import { Link } from '@nextui-org/react';


const rows = ["row-1", "row-2", "row-3", "row-4", "row-5"];


const Row = ({ children }) => {


  const ref = useRef(null);


  const isInView = useInView(ref);

  const divs = {
    "row-1": <div className={`grid grid-cols-8 gap-14 justify-center custom-hidden ${isInView && 'show'}`} ref={ref}> {Children.map(children, child => child)} </div>,
    "row-2": <div className={`grid grid-cols-6 gap-14 justify-center custom-hidden ${isInView && 'show'}`} ref={ref}> {Children.map(children, child => child)} </div>,
    "row-3": <div className={`grid grid-cols-6 gap-11 justify-center custom-hidden ${isInView && 'show'}`} ref={ref}> {Children.map(children, child => child)} </div>,
    "row-4": <div className={`grid grid-cols-5 gap-12 justify-center custom-hidden ${isInView && 'show'}`} ref={ref}> {Children.map(children, child => child)} </div>,
    "row-5": <div className={`grid grid-cols-4 gap-11 justify-center custom-hidden ${isInView && 'show'}`} ref={ref}> {Children.map(children, child => child)} </div>,
  }



  const rowNumber = Children.map(children, child => {
    return child.key.substring(0, 5)
  })
  // console.log(rowNumber[0]);

  return divs[rowNumber[0]];
}




const Skillset = () => {

  const ref = useRef(null);


  const isInView = useInView(ref);
  const isUfoInView = useInView(ref, { once: true });

  return (
    <div id='skillset' className="flex  justify-center content-center max-w-screen-xl mx-auto  container ">


      <div className="flex flex-col gap-y-4 w-[90%] sm:w-[64%]">

        <div className='flex flex-col'>
          <span className='title-style'>Skillset</span>
        </div>



        <div className="biography-text-decoration">
          In my web development journey, I've earned certifications in various frameworks, proudly displayed on my <Link
            isExternal
            href='https://www.linkedin.com/in/ilyes-ismail-benameur-715b9b197/details/certifications/'
            className='biography-text-decoration text-ilyes-purple'
            showAnchorIcon
          >
            LinkedIn
          </Link>
          profile. 🏅 <br />
          <br />
          I've tackled numerous solo and collaborative projects, embracing Scrum agile methodology and exploring diverse design patterns. ✅
          <br />
          <br />
          Additionally, I'm passionate about graphic and UI/UX design, proficient in Adobe Photoshop and Illustrator. I believe in designing software before coding, as demonstrated in my portfolio created using Figma. 🎨
        </div>



        {/* <div className=' w-[70rem] flex justify-center content-center p-3 career-bio'>
          <div className='text-center text-[1.5rem]'>

            In my journey through web development, I've acquired certifications in various frameworks, showcasing them on my <span className='cursor-pointer text-ilyes-purple underline' onClick={() => {
              window.open("https://www.linkedin.com/in/ilyes-ismail-benameur-715b9b197/", '_blank')
            }}>LinkedIn</span> profile.🏅 <br /> <br />
            I've had the privilege of working on numerous solo and collaborative projects. Recognizing the significance of structure and software design, I've embraced the Scrum agile methodology and explored various design patterns. ✅<br /> <br />

            As a passionate hobbyist, I've also delved into graphic and UI/UX design. Proficient in Adobe Photoshop and Illustrator, I've come to appreciate the importance of designing software before diving into coding. 👨‍💻 <br /> <br />

            In fact, I applied this principle to my portfolio, creating the entire UI/UX design using Figma before commencing coding. The results have been exceptionally appealing. 🎨<br /> <br />

          </div>
        </div> */}


        {/* Here goes the skillset */}



        <div className='  flex flex-col items-center gap-10'>


          {/*  {
            rows.map(row => {
              return <Row key={row}>

                {
                  skillset[`${row}`].map((skill, index) => {
                    return <div key={`${row}-${index}`} className='shrink-0'>
                      <img
                        ref={ref}
                        src={skill.icon}
                        alt={skill.name}
                        title={skill.name}
                        className='w-[5rem] h-[5rem] object-contain'
                      />
                    </div>
                  })
                }

              </Row>
            })
          } */}

          {/* {
            rows.map(row => {
              return <div className='h-auto flex flex-row flex-wrap justify-center gap-10 mt-10'>
                {skillset[`${row}`].map((skill, index) => {
                  return <div key={`${row}-${index}`} className='shrink-0'>
                    <img
                      ref={ref}
                      src={skill.icon}
                      alt={skill.name}
                      title={skill.name}
                      className='w-[5rem] h-[5rem] object-contain'
                    />
                  </div>
                })}
              </div>
            })
          } */}




          <div className='h-auto flex flex-row flex-wrap justify-center gap-10 mt-10'>
            {
              skillset.map((skill, index) => {
                return (
                  <img
                    key={index}
                    ref={ref}
                    src={skill.icon}
                    alt={skill.name}
                    title={skill.name}
                    className='md:w-[3.5rem] md:h-[3.5rem] w-[2.5rem] h-[2.5rem] object-contain'
                  />
                )
              })
            }
          </div>

          {/* <div className={`ufo-skillset mt-0 custom-hidden-3 ${isUfoInView && 'show'}`}>
            <img src="assets/ufo-skillset.svg" alt="ufo" className='w-[100rem] object-contain' />
          </div> */}


          <div
            className='animate-bounce w-[2rem] sm:w-[3rem] justify-self-end  py-6 cursor-pointer'
          >
            <a href="#projects">
              <img src="assets/twoarrows.svg" alt="arrows" />
            </a>
          </div>

        </div>

      </div>


    </div >
  )
}

export default Skillset;