import React, { useEffect, useState } from 'react'
import { contacts, downloadPdfEn, openLink } from './shared/materials'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tooltip } from '@nextui-org/react'
import { Button } from '@nextui-org/react';
import { downloadPdf } from './shared/materials';






const Contact = () => {


    const [phoneCopy, setPhoneCopy] = useState("Click to copy");
    const [emailCopy, setEmailCopy] = useState(false);


    useEffect(() => {
        if (phoneCopy === "Copied !") {
            setTimeout(() => { setPhoneCopy("Click to copy"); }, 2500);
        }
        if (emailCopy) {
            setTimeout(() => { setEmailCopy(false); }, 2500);
        }
    }, [phoneCopy, emailCopy])

    // TO BE ADDED TO THE CLASSNAME OF THE CONTACT  bg-[linear-gradient(180deg,rgba(17,7,31,1)_15%,rgba(53,27,90,1)_49%)]

    return (
        <div id="contact" className="flex justify-center content-center  h-[100vh] max-w-screen-xl mx-auto  w-[90%] sm:w-[64%]">


            <div className="flex flex-col justify-center content-center gap-y-12 items-center w-[67%]">
                {/* 
                <div className='flex flex-col items-center'>

                    <img src="assets/contact-underline.svg" alt="underline" />
                </div> */}
                <span className='title-style text-left'>Contact</span>

                <div className="text-[1rem] sm:text-[1.5rem] text-center flex flex-col gap-6">
                    <Dropdown backdrop="blur" className='dark'>
                        <DropdownTrigger>
                            <span className=' underline cursor-pointer select-all'> benameur.ilyesismail@gmail.com </span>
                        </DropdownTrigger>
                        <DropdownMenu>

                            <DropdownItem
                                startContent={<img src='assets/send-icon.svg' className='h-[29px] w-[30px]' />}
                                onClick={() => { window.location = "mailto:benameur.ilyesismail@gmail.com"; }}
                            >
                                Send an Email
                            </DropdownItem>


                            <DropdownItem
                                startContent=
                                {
                                    emailCopy ?
                                        <img src='assets/done-copy.svg' className='h-[35px] w-[35px]' />
                                        :
                                        <img src='assets/copy-icon.svg' className='h-[35px] w-[35px]' />
                                }
                                onClick={() => { navigator.clipboard.writeText("benameur.ilyesismail@gmail.com"); setEmailCopy(true); }}
                            >
                                {emailCopy ? "Copied !" : "Copy Email"}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Tooltip className='dark' content={phoneCopy} placement="bottom">
                        <span
                            className=' underline cursor-pointer'
                            onClick={
                                () => { navigator.clipboard.writeText("+33765271563"); setPhoneCopy("Copied !") }
                            }
                        >
                            +33 7 65 27 15 63
                        </span>
                    </Tooltip>

                </div>
                <div className='flex flex-col sm:flex-row gap-5' >

                    <Button
                        variant='bordered'
                        onClick={downloadPdfEn}
                        className='text-[white] font-[600] h-[3rem]  sm:h-[5rem]  sm:w-[220px] shrink-0  text-[1rem] sm:text-[1.18rem]  border-3 sm:border-4 border-[#b362db]'
                    >
                        Download CV - EN
                        <img src="assets/download-icon.svg" alt="download-icon" className='h-[1rem] sm:h-[1.5rem]' />
                    </Button>

                    <Button
                        variant='bordered'
                        size='sm'
                        onClick={downloadPdf}
                        className='text-[white] font-[600] h-[3rem]  sm:h-[5rem]    text-[1rem] sm:text-[1.5rem]  border-3 sm:border-4 border-[#b362db]'
                    >
                        FR
                        <img src="assets/download-icon.svg" alt="download-icon" className='h-[1rem] sm:h-[1.5rem]' />
                    </Button>
                </div>

                <div className='flex gap-3 sm:gap-5 mb-6'>
                    {
                        contacts.map(contact => (
                            <img key={contact.name} src={contact.icon} alt={contact.name} className='cursor-pointer w-[2rem] sm:w-[2.5rem]' onClick={() => openLink(contact.link)} />
                        ))
                    }
                </div>


                <div className='self-center  w-[10rem]'>
                    <img src="assets/logo.svg" alt="logo" title='This is my logo, and I like it :p' />
                </div>


                <div className="bottom-0 text-center text-[0.8rem] text-[#ccc]">
                    © {new Date().getFullYear()} Benameur Ilyes Ismail.
                </div>
            </div>
        </div>
    )
}

export default Contact